import React, { useEffect, useRef, useState } from "react";
import Hls from "hls.js";
import "./App.scss";

const App = () => {
  const videoRef = useRef(null);
  const [currentChannel, setCurrentChannel] = useState("streamStarSports1");

  const channels = {
    streamStarSports1: "http://5.9.40.126:1344/stream1/stream.m3u8",
    streamStarSports2: "http://5.9.40.126:1344/stream2/stream.m3u8",
    streamStarSports3: "http://5.9.40.126:1344/stream3/stream.m3u8",
    streamSports18: "http://5.9.40.126:1344/stream4/stream.m3u8",
  };

  useEffect(() => {
    const streamUrl = channels[currentChannel]; // Get the URL of the current channel

    if (Hls.isSupported()) {
      const hls = new Hls();
      hls.loadSource(streamUrl);
      hls.attachMedia(videoRef.current);
    } else if (videoRef.current.canPlayType("application/vnd.apple.mpegurl")) {
      videoRef.current.src = streamUrl;
      videoRef.current.muted = true; // Mute the video
    }
  }, [currentChannel]); // Depend on currentChannel

  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.muted = false;
      videoRef.current.autoplay = false;
      videoRef.current.play();
    }
  };

  const getChannelName = (channelKey) => {
    if (channelKey === `streamStarSports1`) {
      return "Star Sports 1";
    } else if (channelKey === `streamStarSports2`) {
      return "Star Sports 2";
    } else if (channelKey === `streamStarSports3`) {
      return "Star Sports 3";
    } else if (channelKey === `streamSports18`) {
      return "Sports 18";
    }
  };

  const handleChannelChange = (channel) => {
    setCurrentChannel(channel);
  };

  return (
    <div className="wrapper">
      <div className="channelNames">
        {Object.keys(channels).map((channel) => (
          <button
            className={`channelName ${currentChannel === channel && "selectedChannel"}`}
            key={channel}
            onClick={() => handleChannelChange(channel)}
          >
            {getChannelName(channel)}
          </button>
        ))}
      </div>
      <video ref={videoRef} controls></video>
      <button className="playButton" onClick={handlePlay}>
        Play
      </button>
    </div>
  );
};

export default App;
